import React from "react"
import styled from "@emotion/styled"

import {
  Body,
  BodySmall,
  borderRadii,
  colours,
  Error,
  horizontal,
  mixins,
  typographyStyles,
  vertical,
} from "../../index"
import QuestionText from "./Internals/QuestionText"

const disabledStyles = `
  opacity: 0.7;
  cursor: default;

  > .checkbox.checked > rect {
    fill: ${colours.greyScale.grey100};
    stroke: ${colours.greyScale.grey100};
  }
`

const Label = styled.label`
  display: block;

  // Needed because the input is positioned absolutely via the 'invisible' mixin
  position: relative;
`

const LabelInner = styled.div<{ disabled: boolean; checkboxTheme: Kind }>`
  ${typographyStyles.body};
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  // 12px top and bottom + 24px line height = 48px standard form input height
  padding: 12px ${horizontal.s};
  border-radius: 4px;

  &:hover {
    > .checkbox.unchecked > rect {
      fill: ${props =>
        props.checkboxTheme === "light"
          ? colours.greyScale.grey25
          : colours.greyScale.grey75};
    }
  }

  ${props => (props.disabled ? disabledStyles : "")}
`

const Input = styled.input`
  ${mixins.invisible};

  &:focus + div {
    ${mixins.focused};
    border-radius: ${borderRadii.xs};
  }
`

const Text = styled.span<{ checkboxTheme: Kind }>`
  margin-left: ${horizontal.s};
  color: ${props =>
    props.checkboxTheme === "light" ? colours.offBlack : colours.white};
  width: 100%;
`

const Svg = styled.svg`
  flex-shrink: 0;
  margin-top: 4px;
`

const Tickbox: React.FunctionComponent<{
  isChecked: boolean
  checkboxTheme: Kind
}> = ({ isChecked, checkboxTheme }) => {
  const themeColor =
    checkboxTheme === "light" ? colours.offBlack : colours.white
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      className={`checkbox ${isChecked ? "checked" : "unchecked"}`}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
    >
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="2"
        stroke={themeColor}
        strokeWidth="2.5"
      />

      {isChecked && (
        <path
          d="M5 10.5658L6.63333 12.3344C6.75904 12.5355 6.96772 12.6591 7.19358 12.6663C7.41944 12.6736 7.63464 12.5635 7.77133 12.3708L13 6"
          stroke={themeColor}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </Svg>
  )
}

type Kind = "light" | "dark"

interface CheckboxProps {
  checked: boolean
  disabled?: boolean
  onChange: (val: boolean) => void
  kind?: Kind
}

export interface CheckboxInputProps extends CheckboxProps {
  border?: boolean
  children?: React.ReactNode
}

const Checkbox: React.FunctionComponent<CheckboxInputProps> = ({
  checked,
  disabled = false,
  children,
  onChange,
  kind = "light",
}) => (
  <Label>
    <Input
      type="checkbox"
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={disabled}
    />

    <LabelInner disabled={disabled} checkboxTheme={kind}>
      <Tickbox isChecked={checked} checkboxTheme={kind} />
      <Text checkboxTheme={kind}>{children}</Text>
    </LabelInner>
  </Label>
)

export const CheckboxInput: React.FunctionComponent<CheckboxInputProps> = ({
  border,
  ...props
}) =>
  border ? (
    <CheckboxInputWrapper checked={props.checked}>
      <Checkbox {...props} kind={props.checked ? "dark" : props.kind} />
    </CheckboxInputWrapper>
  ) : (
    <Checkbox {...props} />
  )

/**
 * CheckboxGroup
 */

const CheckboxInputWrapper = styled.div<{ checked: boolean }>`
  border: 1px solid ${colours.offBlack};
  border-radius: ${borderRadii.xs};
  margin-bottom: ${vertical.xxs};

  ${props => (props.checked ? `background-color: ${colours.offBlack};` : ``)}

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
`

const Legend = styled.legend`
  width: 100%;
`

const CheckboxGroupItemLabel = styled(Body)`
  margin: 0;
`

const CheckboxGroupItemDesc = styled(BodySmall)`
  margin: 0;
`

type OnChangeState = { id: string; checked: boolean }[]

export interface CheckboxGroupProps {
  title: string
  description: string
  checkboxes: {
    id: string
    label: string
    checked: boolean
    description?: string
  }[]
  onChange: (state: OnChangeState, changedId: string) => void
  error?: string
}

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = ({
  title,
  description,
  checkboxes,
  onChange,
  error = "",
}) => (
  <Fieldset>
    <Legend>
      <QuestionText title={title} description={description} />
    </Legend>

    {checkboxes.map(checkbox => {
      return (
        <CheckboxInput
          key={checkbox.id}
          checked={checkbox.checked}
          kind={checkbox.checked ? "dark" : "light"}
          onChange={val => {
            onChange(
              checkboxes.map(cb => ({
                id: cb.id,
                checked: cb.id === checkbox.id ? val : cb.checked,
              })),
              checkbox.id
            )
          }}
          border={true}
        >
          <CheckboxGroupItemLabel>{checkbox.label}</CheckboxGroupItemLabel>
          {checkbox.description && (
            <CheckboxGroupItemDesc>
              {checkbox.description}
            </CheckboxGroupItemDesc>
          )}
        </CheckboxInput>
      )
    })}
    {error && <Error message={error} />}
  </Fieldset>
)
